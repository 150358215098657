@tailwind base;
@tailwind components;
@tailwind utilities;

#toggle-switch::after {
    content: '';
    @apply h-5 w-5 rounded-full absolute left-0 top-0 transform scale-110 transition duration-200; 
}

.react-select__control:focus {
    border-color: #FBBF24;
    box-shadow: 0 0 0 2px #FBBF24;
  }
  
.focus:border-yellow-400:focus {
    border-color: #FBBF24;
    outline: none;
    box-shadow: 0 0 0 2px #FBBF24;
  }

.transition-all {
    transition: all 0.3s ease-out;
  }