.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  
  .login-card {
    background-color: white;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-title {
    margin-bottom: 1rem;
  }
  
  .google-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4285f4;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .google-btn:hover {
    background-color: #3367d6;
  }
  
  .google-btn .material-icons {
    margin-right: 0.5rem;
  }
  